import { useEffect, useRef, useState, useContext } from "react"
import { Translate } from "../helpers/Translator"
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete } from "@mui/material";
import axios from "axios";
import { LanguageContext } from "../helpers/LanguageContext";
import { formatDateIso } from "../helpers/funcs";
import { Navigate } from "react-router-dom";

export function NewSpend() {
    const { lang } = useContext(LanguageContext)
    const [forward, setForward] = useState(false)
    const currDate = formatDateIso(new Date())
    const amount = useRef("")
    const [currency, setCurrency] = useState(localStorage.getItem("spend-diary-currency"))
    const commodity = useRef("")
    const spendDate = useRef(new Date())
    const [err, setErr] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [commodities, setCommodities] = useState([])

    useEffect(() => {
        axios.get("/api/used-spend-types").then((res) => {
            if (res.data.result) {
                setCommodities(res.data.spend_types)
            }
        }).catch((err) => {

        })
    }, [])

    useEffect(() => {
        setErrorMessage(err)
    }, [err])


    const post_spend = (e) => {
        setErr("")
        e.preventDefault()
        console.log("Sending spend");
        var spend = {
            commodity: commodity.current.value,
            spend_date: spendDate.current.value,
            currency: currency,
            amount: parseFloat(amount.current.value)
        }
        axios.post("/api/spend", spend).then((res) => {
            if (res.data.result) {
                setForward(true)
            } else {
                setErr(Translate(lang, "server", res.data.message))
            }
        }).catch((err) => {
            console.log(err)
            setErr(Translate(lang, "server", "internal.error"))

        })
    }
    if (forward) {
        return <Navigate to="/" />
    } else {
        return (
            <div className="m-2">
                <h2 className="text-center my-3">{Translate(lang, "client", "page.new.spend.title")}</h2>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex flex-column">
                        <form onSubmit={post_spend}>
                            <div className="d-flex flex-row my-3">
                                <div className="mx-1">
                                    <TextField label={Translate(lang, "client", "page.new.spend.label.amount")} inputRef={amount} variant="outlined" required />
                                </div>
                                <div className="mx-1">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">{Translate(lang, "client", "page.new.spend.label.currency")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Age"
                                            onChange={(e) => setCurrency(e.target.value)}
                                            defaultValue={localStorage.getItem("spend-diary-currency")}
                                        >
                                            <MenuItem value="EUR">EUR</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="SEK">SEK</MenuItem>
                                            <MenuItem value="CAD">CAD</MenuItem>
                                            <MenuItem value="GBP">GBP</MenuItem>
                                            <MenuItem value="CHF">CHF</MenuItem>
                                            <MenuItem value="DKK">DKK</MenuItem>
                                            <MenuItem value="NOK">NOK</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="my-3">
                                <Autocomplete
                                    disablePortal
                                    options={commodities}
                                    freeSolo
                                    renderInput={(params) => <TextField required {...params} inputRef={commodity} label={Translate(lang, "client", "page.new.spend.label.commodities")} />}
                                />
                            </div>
                            <div className="my-3 w-full flex flex-col">
                                <div>
                                    <TextField required fullWidth inputRef={spendDate} type="date" defaultValue={currDate} label={Translate(lang, "client", "page.new.spend.label.date")} />
                                </div>
                            </div>
                            <div className="text-danger text-center my-3 font-weight-bold">
                                <h5>

                                    {errorMessage &&
                                        errorMessage
                                    }
                                </h5>
                            </div>
                            <div>
                                <button className="own-btn btn-gold w-100" type="submit">{Translate(lang, "client", "page.new.spend.button.submit")}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
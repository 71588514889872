import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SpendDiaryContext } from "./SpendDiaryContext";

export function PublicRoute({ redirectPath = "/dashboard", children}) {
    const { user } = useContext(SpendDiaryContext)
    if(user !== null) {
        console.log("User exists, sending you to index")
        return <Navigate to={redirectPath} replace />;
    }
    
    return children
}
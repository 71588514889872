const en = {
    server: {
        "internal.error": "Internal error, please try again later.",
        "get.all.spends.success": "",
        "spend.analysis.success": "",
        "auth.emailaddress.taken": "This email address is already in use.",
        "auth.user.created": "Welcome!",
        "auth.username.taken": "Your username is already in use",
        "auth.login.user.not.found": "We don't know anyone with that name.",
        "auth.login.password.incorrect": "The password you provided is incorrect.",
        "auth.login.success": "Welcome back!",
        "new.spend.insert.success": "New spend saved.",
        "update.spend.success": "Spend has been updated.",
        "delete.spend.success": "Spend has been deleted."
    },
    client: {
        "change.language": "DE",
        "page.register.title": "Keep an eye on your expenses...",
        "page.register.label.username": "Username:",
        "page.register.label.email": "Email address:",
        "page.register.label.password": "Password:",
        "page.register.button.register": "REGISTER",
        "page.login.label.username": "Username or email address:",
        "page.login.title": "Welcome back",
        "page.login.button.login": "LOGIN",
        "page.login.button.logout": "LOGOUT",
        "page.dashboard.label.spend.this.month": "Your spend this month:",
        "page.register.label.currency": "Your home currency:",
        "page.dashbaord.button.new.spend": "NEW SPEND",
        "page.dashbaord.button.my.spends": "MY SPENDS",
        "page.dashbaord.button.spend.analysis": "SPEND ANALYSIS",
        "page.new.spend.title": "New Spend",
        "page.new.spend.label.amount": "Amount",
        "page.new.spend.label.currency": "Currency",
        "page.new.spend.label.commodities": "Expense for... (e.g. groceries)",
        "page.new.spend.label.date": "Date of spend",
        "page.new.spend.button.submit": "SAVE SPEND",
        "page.my.spends.table.head.date": "Date",
        "page.my.spends.table.head.type": "Type",
        "page.my.spends.update.spend": "Update Spend",
        "page.my.spends.update.success": "Thanks for the update!",
        "pages.spend.analysis.title": "Spend Analysis",
        "page.spend.analysis.start.date": "Start date",
        "page.spend.analysis.end.date": "End date",
        "page.spend.analysis.analyze": "ANALYZE",
        "page.start.button.get.started": "GET STARTED",
    }
}

export { en }
import { Link } from "react-router-dom"
import bgImg from "../img/BgImg.PNG"
import { TypeAnimation } from 'react-type-animation';
import { Translate } from "../helpers/Translator";

export function StartPage() {
    const lang = "en"

    return (
        <div className="m-2">
            <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column">
                    <div>

                        <img src={bgImg} className="fit-image"></img>
                    </div>
                    <div className="mt-4">

                        <TypeAnimation
                            sequence={[
                                "Keep track of your expenses...",
                                1400,
                                "... and get your sh*t together...",
                                1400,
                                "... with SpendDiary",
                                2000, () => { }
                            ]}
                            wrapper="div"
                            speed={80}
                            deletionSpeed={80}
                            repeat={Infinity}
                            style={{ fontSize: "1.5em", textAlign: "center", fontWeight: "bold" }}
                        />
                    </div>
                    <div className="mt-4">
                        <Link to="/register">
                            <button className="own-btn btn-gold w-100" type="button">{Translate(lang, "client", "page.start.button.get.started")}</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
import './App.css';
import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./pages/Dashboard"
import { Login } from "./pages/Login"
import { Register } from "./pages/Register"
import { NewSpend } from "./pages/NewSpend"
import { MySpends } from "./pages/MySpends"
import { StartPage } from "./pages/StartPage"
import { SpendAnalysis } from "./pages/SpendAnalysis"
import { ProtectedRoute } from "./auth/ProtectedRoute"
import { PublicRoute } from "./auth/PublicRoute"
import { SpendDiaryContext } from './auth/SpendDiaryContext'
import { LanguageContext } from './helpers/LanguageContext'
import { useMemo, useState, useEffect } from 'react';
import { TopPanel } from './Components/TopPanel'
import axios from 'axios';

function App() {
  const [user, setUser] = useState(null)
  const contextValue = useMemo(
    () => ({ user, setUser }),
    [user]
  )
  const [lang, setLang] = useState("en")
  const langContextValue = useMemo(
    () => ({ lang, setLang }),
    [lang]
  )
  useEffect(() => {
    if (localStorage.getItem("spend-diary-user") !== null) {
      // check if user is stil logged in
      axios.get("/is-logged-in").then((res) => {
        if(res.data.result) {
          // use is logged in
          localStorage.setItem("spend-diary-user", res.data.username)
        } else {
          console.log("You are not logged in");
        }
      }).catch((err) => {

      })
    }
  }, [])


  return (
    <>
      <LanguageContext.Provider value={langContextValue}>

        <SpendDiaryContext.Provider value={contextValue}>
          <TopPanel />
          <Routes>
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
            <Route path="/new-spend" element={<ProtectedRoute><NewSpend /></ProtectedRoute>} />
            <Route path="/my-spends" element={<ProtectedRoute><MySpends /></ProtectedRoute>} />
            <Route path="/spend-analysis" element={<ProtectedRoute><SpendAnalysis /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/" element={<PublicRoute><StartPage /></PublicRoute>} />
          </Routes>
        </SpendDiaryContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}

export default App;

import { useContext } from "react"
import { SpendDiaryContext } from "../auth/SpendDiaryContext"
import { LanguageContext } from "../helpers/LanguageContext"
import { Translate } from "../helpers/Translator"
import { Link, useLocation } from "react-router-dom"
import logo from '../img/Logo.png'

export function TopPanel() {
    const location = useLocation()
    const lang = useContext(LanguageContext)
    const { user, setUser } = useContext(SpendDiaryContext)
    const logout = () => {
        console.log("Nulling user");
        setUser(null)
        localStorage.removeItem("spend-diary-user")
    }
    // const { lang, setLang } = useContext(LanguageContext)
    // const changeLanguage = () => {
    //     if (lang === "de") setLang("en")
    //     else if (lang === "en") setLang("de")
    // }

    return (
        <header className="top-panel p-3">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center justtify-content-center">
                    <Link to="/dashboard">

                        <img src={logo} alt="Spend diary logo" className="logo" />
                    </Link>
                    {/* <h4 className="text-white text-underlined header-text  h-90" onClick={changeLanguage}><u>{Translate(lang, "client", "change.language")}</u></h4> */}
                </div>
                <div className="d-flex align-items-center justtify-content-center">

                    {user !== null ? <button onClick={logout} className="own-btn btn-gold px-4 btn-header text-center">{Translate(lang, "client", "page.login.button.logout")}</button> : <></>}
                    {user === null && location.pathname !== "/register" && location.pathname !== "/" ? <Link to="/register"><button className="own-btn btn-gold mx-3 btn-header text-center">{Translate(lang, "client", "page.register.button.register")}</button></Link> : <></>}
                    {user === null ? <Link to="/login"><button className="own-btn btn-gold btn-header text-center">{Translate(lang, "client", "page.login.button.login")}</button></Link> : <></>}
                </div>
            </div>
        </header>
    )
}
const de = {
    server: {
        "internal.error": "Interner Fehler, bitte versuche es später nochmal.",
        "get.all.spends.success": "",
        "spend.analysis.success": "",
        "auth.emailaddress.taken": "Diese E-Mail Adresse wird bereits verwendet.",
        "auth.user.created": "Welcome!",
        "auth.login.user.not.found": "Wir kennen niemanden mit diesem Namen.",
        "auth.login.password.incorrect": "Das ist das falsche Passwort...",
        "auth.login.success": "Willkommen zurück!",
        "new.spend.insert.success": "Neue Ausgabe hinzugefügt.",
        "update.spend.success": "Ausgabe wurde aktualisiert.",
        "delete.spend.success": "Ausgabe wurde gelöscht."
    },
    client: {
        "change.language": "EN",
        "page.register.title": "Alle Ausgaben, immer im Blick",
    }
}

export { de }
import { useContext, useState, useRef } from "react"
import { SpendDiaryContext } from "../auth/SpendDiaryContext";
import { Translate } from "../helpers/Translator";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Navigate } from "react-router-dom";
import axios from "axios";
import { LanguageContext } from "../helpers/LanguageContext";

export function Login() {
    const lang = useContext(LanguageContext)
    const username = useRef("")
    const password = useRef("")
    const [error, setError] = useState("")
    const [loginSuccess, setLoginSuccess] = useState(false)

    const { setUser } = useContext(SpendDiaryContext);
    const login = (e) => {
        setError("")
        e.preventDefault()
        var userObj = {
            username: username.current.value,
            password: password.current.value
        }
        axios.post("/login", userObj).then((res) => {
            if (res.data.result) {
                setError(res.data.message)
                setUser(username)
                setLoginSuccess(true)
                localStorage.setItem("spend-diary-user", username)
            } else {
                setError(res.data.message)
            }
        }).catch((err) => {
            setError("internal.error")
        })
    }
    if (loginSuccess) {
        return <Navigate to="/" />;
    } else {
        return (
            <div className="m-2">
                <h2 className="text-center my-4">{Translate(lang, "client", "page.login.title")}</h2>
                <form onSubmit={login}>
                    <div className=" d-flex justify-content-center align-items-center">
                        <div className="w-100 login-screen">
                            <label htmlFor="username">{Translate(lang, "client", "page.login.label.username")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon h-100">
                                    <FontAwesomeIcon className="text-gold" icon={solid('user')} />
                                </div>
                                <div className="w-100  h-100">
                                    <input className="w-100 login-input" id="username" name="username" type="text" ref={username} required ></input>
                                </div>
                            </div>
                            <label htmlFor="password">{Translate(lang, "client", "page.register.label.password")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon">
                                    <FontAwesomeIcon className="text-gold" icon={solid('lock')} />
                                </div>
                                <div className="w-100">
                                    <div>
                                        <input className="w-100 login-input" id="password" name="password" type="password" ref={password} required></input>
                                    </div>
                                </div>
                            </div>

                            {error !== "" ? <h5 className="w-100 text-danger text-center">{Translate(lang, "server", error)}</h5> : ""}
                            <button className="own-btn btn-gold mt-2 w-100 p-2" type="submit"><b>{Translate(lang, "client", "page.login.button.login")}</b></button>
                        </div>
                    </div>
                </form>
            </div >
        )
    }
}
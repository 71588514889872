import { useState, useRef, useContext } from "react"
import axios from "axios"
import { Navigate } from "react-router-dom"
import { Translate } from "../helpers/Translator"
import { SpendDiaryContext } from "../auth/SpendDiaryContext"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { LanguageContext } from "../helpers/LanguageContext"

export function Register() {
    const lang = useContext(LanguageContext)
    const username = useRef("")
    const email = useRef("")
    const password = useRef("")
    const defaultCurrency = useRef("")
    const [error, setError] = useState("")
    const [registerSuccess, setRegisterSuccess] = useState(false)

    const { setUser } = useContext(SpendDiaryContext);
    const setUserHandler = (unam) => setUser({
        unam: unam
    })

    const register = (e) => {
        setError("")
        e.preventDefault()
        var userObj = {
            username: username.current.value,
            password: password.current.value,
            email_address: email.current.value,
            currency: defaultCurrency.current.value
        }
        console.log("Registering user with the following data:");
        console.log(userObj)
        axios.post("/register", userObj).then((res) => {
            console.log(res.data)
            if (res.data.result) {
                setUserHandler(username)
                setRegisterSuccess(true)
                localStorage.setItem("spend-diary-user", username)
            } else {
                console.log("Setting error to")
                console.log(res.data.message);
                setError(res.data.message)
            }
        }).catch((err) => {
            console.log(err);
            setError("internal.error")
        })
    }
    if (registerSuccess) {
        return <Navigate to="/" />;
    } else {

        return (
            <div className="m-2">
                {/* <h2 className="text-center my-4">{Translate(lang, "client", "page.register.title")}</h2> */}
                <form onSubmit={register}>
                    <div className=" d-flex justify-content-center align-items-center mt-4">
                        <div className="w-100 login-screen">
                            <label htmlFor="username">{Translate(lang, "client", "page.register.label.username")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon h-100">
                                    <FontAwesomeIcon className="text-gold" icon={solid('user')} />
                                </div>
                                <div className="w-100  h-100">
                                    <input className="w-100 login-input" id="username" name="username" type="text" ref={username} required ></input>
                                </div>
                            </div>
                            <label htmlFor="email">{Translate(lang, "client", "page.register.label.email")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon">
                                    <FontAwesomeIcon className="text-gold" icon={solid('at')} />
                                </div>
                                <div className="w-100">
                                    <div>
                                        <input className="w-100 login-input" id="email" name="email" type="email" ref={email} required></input>
                                    </div>
                                </div>
                            </div>
                            <label htmlFor="password">{Translate(lang, "client", "page.register.label.password")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon">
                                    <FontAwesomeIcon className="text-gold" icon={solid('lock')} />

                                </div>
                                <div className="w-100">
                                    <div>

                                        <input className="w-100 login-input" id="password" name="password" type="password" ref={password} required></input>
                                    </div>
                                </div>
                            </div>
                            <label htmlFor="currency">{Translate(lang, "client", "page.register.label.currency")}</label><br></br>
                            <div className="d-flex flew-row w-100 mb-3">
                                <div className="login-input-icon">
                                    <FontAwesomeIcon className="text-gold" icon={solid('dollar-sign')} />
                                </div>
                                <div className="w-100">
                                    <div>
                                        <select className="w-100 login-input" id="currency" name="currency" ref={defaultCurrency} required>
                                            <option>EUR</option>
                                            <option>USD</option>
                                            <option>SEK</option>
                                            <option>CAD</option>
                                            <option>GBP</option>
                                            <option>CHF</option>
                                            <option>DKK</option>
                                            <option>NOK</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* {error !== "" ? <h5 className="w-100 text-danger text-center">Crazy</h5> : ""} */}
                            {error !== "" ? <h5 className="w-100 text-danger text-center">{Translate(lang, "server", error)}</h5> : ""}
                            <button className="own-btn btn-gold mt-2 w-100 p-2" type="submit"><b>{Translate(lang, "client", "page.register.button.register")}</b></button>
                        </div>
                    </div>
                </form>
            </div >
        )
    }
}
import { de } from "./de"
import { en } from "./en"

export function Translate(lang, scope, key) {
    
    if(lang === "de") {
        if(de[scope][key] === undefined) {
            return en[scope][key]
        }
        return de[scope][key];
    } else {

        return en[scope][key]
    }
}
function pad(number) { return number < 10 ? '0' + number : number }

export function formatDateIso(dateObj) {
    try {
        return dateObj.getFullYear() + '-' + pad(dateObj.getMonth() + 1) + '-' + pad(dateObj.getDate())
    } catch(err) {
        console.log(err)
        return ""
    }
}

export function format_date(currecy, date) {
    const date_parts = date.split("-")
    // check if there are 2 dashes in the date
    if(date_parts.length !== 3) {
        return date;
    }
    return currecy === "USD" ? date_parts[1] + "/" + date_parts[0] + "/" + date_parts[2] : date_parts[2] + "." + date_parts[1] + "." + date_parts[0]
}

export function format_number(currecy, amount) {
    return currecy === "USD" ? amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : amount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

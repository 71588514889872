import { Translate } from "../helpers/Translator"
import { useState, useEffect, useContext } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { format_number } from "../helpers/funcs";
import { LanguageContext } from "../helpers/LanguageContext"

export function Dashboard() {
    const lang = useContext(LanguageContext)
    const [monthSpend, setMonthSpend] = useState({})
    useEffect(() => {
        axios.get("/api/spend-this-month").then((res) => {
            if (res.data.result) {
                var displayCurr = res.data.currency;
                if (res.data.currency === "EUR") displayCurr = "€"
                if (res.data.currency === "USD") displayCurr = "$"
                localStorage.setItem("spend-diary-currency", res.data.currency)
                setMonthSpend({
                    currency: displayCurr,
                    amount: res.data.amount
                })
            }
        })

    }, [])

    return (
        <div className="m-2">
            <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex flex-column">
                    <div className="border-gold my-2">
                        <div className="bg-white d-flex justify-content-between">
                            <div className="d-flex align-items-center justify-content-center m-2">
                                <h2><FontAwesomeIcon className="text-gold" icon={solid('coins')} /></h2>
                            </div>
                            <div className="m-2">
                                <div>
                                    <h5>
                                        {Translate(lang, "client", "page.dashboard.label.spend.this.month")}
                                    </h5>
                                </div>
                                <div className="">
                                    <h4>{monthSpend.amount ? format_number(monthSpend.currency, monthSpend.amount) : "0"} {monthSpend.currency}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center my-2 w-100">
                        <Link to="/new-spend">
                            <button className="own-btn btn-gold btn-header text-center w-100">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className="w-20">
                                        <span className="lead"><FontAwesomeIcon className="" icon={solid('square-plus')} /></span>
                                    </div>
                                    <div className="w-80">
                                        <span className="float-left mx-3">
                                            {Translate(lang, "client", "page.dashbaord.button.new.spend")}
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className="text-center my-2 w-100">
                        <Link to="/my-spends">
                            <button className="own-btn btn-gold btn-header text-center w-100">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className="w-20">
                                        <span className="lead"><FontAwesomeIcon className="" icon={solid('book')} /></span>
                                    </div>
                                    <div className="w-80">
                                        <span className="float-left mx-3">
                                            {Translate(lang, "client", "page.dashbaord.button.my.spends")}
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                    </div>
                    <div className="text-center my-2 w-100">
                        <Link to="/spend-analysis">
                            <button className="own-btn btn-gold w-100">
                                <div className="d-flex flex-row justify-content-center align-items-center">
                                    <div className="w-20">
                                        <span className="lead"><FontAwesomeIcon className="" icon={solid('chart-column')} /></span>
                                    </div>
                                    <div className="w-80">
                                        <span className="float-left mx-3">
                                            {Translate(lang, "client", "page.dashbaord.button.spend.analysis")}
                                        </span>
                                    </div>
                                </div>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
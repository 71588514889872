import axios from "axios";
import { useContext, useRef, useState } from "react";
import { LanguageContext } from "../helpers/LanguageContext";
import { Translate } from "../helpers/Translator";
import { TextField } from "@mui/material";
import { formatDateIso } from "../helpers/funcs";
import { format_number } from "../helpers/funcs";

export function SpendAnalysis() {
    const currency = localStorage.getItem("spend-diary-currency");
    const now = new Date();
    const firstDay = formatDateIso(new Date(now.getFullYear(), now.getMonth(), 1))
    const lastDay = formatDateIso(new Date(now.getFullYear(), now.getMonth() + 1, 0))
    const [errorMessage, setErrorMessage] = useState("")
    const [spends, setSpends] = useState([])

    const dateFrom = useRef()
    const dateTo = useRef()

    const startAnalysis = (e) => {
        e.preventDefault()
        setErrorMessage("")
        console.log(dateFrom.current.value)
        console.log(dateTo.current.value)
        const data = {
            start_date: dateFrom.current.value,
            end_date: dateTo.current.value
        }
        axios.post("/api/spend-analysis", data).then((res) => {
            if (res.data.result) {
                console.log(res.data.spends)
                setSpends(res.data.spends)
            }
        }).catch((err) => {
            setErrorMessage(Translate(lang, "server", "internal.error"))
        })

    }

    const { lang } = useContext(LanguageContext)
    return (
        <div className="m-2">
            <h2 className="text-center">{Translate(lang, "client", "pages.spend.analysis.title")}</h2>
            <div className="d-flex justify-content-center align-items-center my-4">
                <div className="d-flex flex-column">
                    <form onSubmit={startAnalysis}>
                        <div className="d-flex my-2">
                            <div className="mx-1">
                                <TextField required fullWidth inputRef={dateFrom} defaultValue={firstDay} type="date" label={Translate(lang, "client", "page.spend.analysis.start.date")} />
                            </div>
                            <div className="mx-1">
                                <TextField required fullWidth inputRef={dateTo} type="date" defaultValue={lastDay} label={Translate(lang, "client", "page.spend.analysis.end.date")} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center m-1">
                            <button className="own-btn btn-gold w-100" type="submit">{Translate(lang, "client", "page.spend.analysis.analyze")}</button>
                        </div>
                    </form>
                    <div className="text-danger text-center my-3 font-weight-bold">
                        <h5>

                            {errorMessage !== "" &&
                                errorMessage
                            }
                        </h5>
                    </div>
                    {
                        spends.length > 0 &&
                        <div className="table-responsive">

                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>{Translate(lang, "client", "page.my.spends.table.head.type")}</th>
                                        <th>{Translate(lang, "client", "page.new.spend.label.amount")} ({currency})</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {spends.map((spend) => {
                                        return (<tr key={spend.id}>
                                            <td className="py-3">{spend.commodity}</td>
                                            <td className="py-3">{format_number(currency, spend.amount)}</td>
                                        </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
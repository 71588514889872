import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { format_number, format_date } from "../helpers/funcs"
import { LanguageContext } from "../helpers/LanguageContext"
import { Translate } from "../helpers/Translator"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export function MySpends() {
    const currency = localStorage.getItem("spend-diary-currency");
    const lang = useContext(LanguageContext)
    const [modalOpen, setModalOpen] = useState(false)
    const [err, setErr] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [amount, setAmount] = useState(0)
    const [curr, setCurr] = useState(currency)
    const [commodity, setCommodity] = useState("")
    const [spendDate, setSpendDate] = useState(0)
    const [spendId, setSpendId] = useState(0)
    const [spends, setSpends] = useState([])
    const [commodities, setCommodities] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [refetchData, setRefetchData] = useState(false)

    const handleClose = () => setModalOpen(false)

    useEffect(() => {
        axios.get("/api/all-spends").then((res) => {
            if (res.data.result) {
                setSpends(res.data.spends)
            }
        }).catch((err) => {
            setErr(Translate(lang, "server", "internal.error"))
        })
    }, [lang, refetchData])

    const put_spend = (e) => {
        e.preventDefault();
        setErrorMessage("")
        setSuccessMessage("")
        const data = {
            id: spendId,
            commodity: commodity,
            amount: parseFloat(amount),
            currency: curr,
            spend_date: spendDate
        }
        // console.log("Updating...")
        // console.log(data)
        axios.put("/api/spend", data).then((res) => {
            setSuccessMessage(Translate(lang, "client", "page.my.spends.update.success"))
            setRefetchData(!refetchData)
        }).catch((err) => {
            setErrorMessage(Translate(lang, "server", "internal.error"))
        })
        
    }

    const openEditModal = (spendid, spend_date, commodity, amount) => {
        setErrorMessage("")
        setSuccessMessage("")
        axios.get("/api/used-spend-types").then((res) => {
            if (res.data.result) {
                setCommodities(res.data.spend_types)
            }
        }).catch((err) => {

        })
        setModalOpen(true)
        setSpendDate(spend_date)
        setCommodity(commodity)
        setAmount(amount.toFixed(2))
        setSpendId(spendid)
    }


    return (
        <div>
                <Modal
                    open={modalOpen}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <div className="d-flex flex-column">
                            <div className="d-flex w-100 justify-content-end">
                                <button className="own-btn btn-gold px-3" onClick={(e) => { setModalOpen(false) }}>X</button>
                            </div>
                            <div>
                                <form onSubmit={put_spend}>
                                <div className="d-flex flex-row my-3">
                                    <div className="mx-1">
                                        <TextField label={Translate(lang, "client", "page.new.spend.label.amount")} value={amount} onChange={(e) => { setAmount(e.target.value) }} variant="outlined" required />
                                    </div>
                                    <div className="mx-1">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{Translate(lang, "client", "page.new.spend.label.currency")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Age"
                                                onChange={(e) => setCurr(e.target.value)}
                                                defaultValue={curr}
                                            >
                                                <MenuItem value="EUR">EUR</MenuItem>
                                                <MenuItem value="USD">USD</MenuItem>
                                                <MenuItem value="SEK">SEK</MenuItem>
                                                <MenuItem value="CAD">CAD</MenuItem>
                                                <MenuItem value="GBP">GBP</MenuItem>
                                                <MenuItem value="CHF">CHF</MenuItem>
                                                <MenuItem value="DKK">DKK</MenuItem>
                                                <MenuItem value="NOK">NOK</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="my-3">
                                    <Autocomplete
                                        disablePortal
                                        options={commodities}
                                        freeSolo
                                        inputValue={commodity}
                                        onInputChange={(e, newValue) => { setCommodity(newValue) }}
                                        renderInput={(params) => <TextField required {...params} label={Translate(lang, "client", "page.new.spend.label.commodities")} />}
                                    />
                                </div>
                                <div className="my-3 w-full flex flex-col">
                                    <div>
                                        <TextField required fullWidth onChange={(e) => { setSpendDate(e.target.value) }} type="date" defaultValue={spendDate} label={Translate(lang, "client", "page.new.spend.label.date")} />
                                    </div>
                                </div>
                                {
                                    successMessage !== "" &&
                                    <div>
                                        <h5 className="text-center text-success">{successMessage}</h5>
                                    </div>
                                }
                                <div className="text-danger text-center my-3 font-weight-bold">
                                    <h5>

                                        {errorMessage !== "" &&
                                            errorMessage
                                        }
                                    </h5>
                                </div>
                                <div>
                                    <button className="own-btn btn-gold w-100" type="submit">{Translate(lang, "client", "page.new.spend.button.submit")}</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </Box>
                </Modal>
            <div>

            </div>
            <div className="m-2">
                <div className="d-flex justify-content-center align-items-center">
                    {
                        err &&
                        <div>
                            <h5 className="text-center text-danger">{err}</h5>
                        </div>
                    }
                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>{Translate(lang, "client", "page.my.spends.table.head.date")}</th>
                                    <th>{Translate(lang, "client", "page.my.spends.table.head.type")}</th>
                                    <th colSpan={2}>{Translate(lang, "client", "page.new.spend.label.amount")} ({currency})</th>
                                </tr>
                            </thead>
                            <tbody>

                                {spends.map((spend) => {
                                    return (<tr key={spend.id}>
                                        <td className="py-3">{format_date(currency, spend.spend_date)}</td>
                                        <td className="p-3">{spend.commodity}</td>
                                        <td className="py-3">{format_number(currency, spend.amount)}</td>
                                        <td className="py-3"><FontAwesomeIcon onClick={(e) => openEditModal(spend.id, spend.spend_date, spend.commodity, spend.amount)} className="text-gold mouse-pointer" icon={solid('pen-to-square')} /></td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}